import React from 'react';
import { useNavigate } from 'react-router-dom';

const CreateAgentPage = () => {
  const navigate = useNavigate();

  const handleAddSystemPromptClick = () => {
    navigate('/agents/create-an-agent/add-a-system-prompt');
  };

  return (
    <div className="flex flex-col items-center p-8 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-8">Create an Agent</h1>
      <div className="w-full max-w-md">
        <button
          onClick={handleAddSystemPromptClick}
          className="w-full px-4 py-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
        >
          Add a System Prompt
        </button>
        <button className="w-full px-4 py-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition">
          Add a Skill or Function
        </button>
        <button className="w-full px-4 py-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition">
          Add Knowledge or Context
        </button>
        <button className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition">
          Add a Workflow
        </button>
      </div>
    </div>
  );
};

export default CreateAgentPage;
