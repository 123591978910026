// src/components/PaperTasks.tsx
import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_TASKS_BY_RESEARCH_PAPER_ID } from '../graphql/papers/papersQueries';

interface Task {
  id: string;
  type: string;
  output: string;
}

interface PaperTasksProps {
  researchPaperId: string;
}

const PaperTasks: React.FC<PaperTasksProps> = ({ researchPaperId }) => {
  const { loading, error, data } = useQuery(GET_TASKS_BY_RESEARCH_PAPER_ID, {
    variables: { researchPaperId },
  });

  if (loading) return <p>Loading tasks...</p>;
  if (error) return <p>Error loading tasks: {error.message}</p>;

  return (
    <div className="mt-6 space-y-4">
      <h3 className="text-xl font-bold mb-4">Associated Tasks</h3>
      {data.tasksbyResearchPaperId.length > 0 ? (
        data.tasksbyResearchPaperId.map((task: Task) => (
          <div key={task.id} className="bg-white border border-gray-200 rounded-lg shadow-sm p-4">
            <p className="text-gray-900 font-semibold"><strong>Type:</strong> {task.type}</p>
            <p className="text-gray-700 mt-2"><strong>Output:</strong> <span className="whitespace-pre-wrap">{task.output}</span></p>
          </div>
        ))
      ) : (
        <p>No tasks found for this paper.</p>
      )}
    </div>
  );
};

export default PaperTasks;
