// src/pages/YCRFSPage.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_TOPICS } from '../graphql/workflows/ycRFSQueries';

// Type definitions
interface Topic {
  id: string;
  topic: string;
  description: string;
  urlRoute: string;
}

interface TopicsQueryData {
  ycTopics: Topic[];
}

const YCRFSPage = () => {
  const { loading, error, data } = useQuery<TopicsQueryData>(GET_TOPICS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className="p-8">
      <h2 className="text-xl font-bold mb-4">Request for Startups Topics</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        {data?.ycTopics.map(({ id, topic, description, urlRoute }) => (
          <div key={id} className="mb-4 bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow">
            <h3 className="text-lg font-semibold text-center">{topic}</h3>
            <p className="text-gray-600 text-sm mt-2 text-left">{description}</p>
            <div className="text-right mt-4">
              <Link to={`/workflows/YC-RFS/${urlRoute}`}
                    className="inline-block bg-blue-500 text-white text-sm font-semibold px-4 py-2 rounded hover:bg-blue-600 transition-colors">
                View Startup Ideas
              </Link>
</div>

          </div>
        ))}
      </div>
    </div>
  );
};

export default YCRFSPage;