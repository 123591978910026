import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PageTracker } from './utilities/ga4-analytics';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
// import LoginPage from './pages/LoginPage';
import AgentsPage from './pages/AgentsPage';
import CreateAgentPage from './pages/CreateAgentPage'; // Import the new component
import AddSystemPromptPage from './pages/AddSystemPromptPage'; // Import the new component
import PapersPage from './pages/PapersPage';
import WorkflowsPage from './pages/WorkflowsPage';
import YCRFSPage from './pages/YCRFSPage';
import YCRFSTopicPage from './pages/YCRFSTopicPage'; // Import the new component
import SelectedPaperPage from './pages/SelectedPaperPage';
import ELI12Page from './pages/ELI12Page';

const App = () => {
  return (
    <Router>
      <PageTracker />
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/login" element={<LoginPage />} /> */}
        <Route path="/agents" element={<AgentsPage />} />
        <Route path="/agents/create-an-agent" element={<CreateAgentPage />} />
        <Route path="/agents/create-an-agent/add-a-system-prompt" element={<AddSystemPromptPage />} />
        <Route path="/papers" element={<PapersPage />} />
        <Route path="/workflows" element={<WorkflowsPage />} />
        <Route path="/workflows/YC-RFS" element={<YCRFSPage />} />
        <Route path="/workflows/YC-RFS/:urlRoute" element={<YCRFSTopicPage />} />
        <Route path="/workflows/ELI-12" element={<ELI12Page />} />
        <Route path="/papers/:paperId" element={<SelectedPaperPage />} />
      </Routes>
    </Router>
  );
};

export default App;
