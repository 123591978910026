import React from 'react';

const AddSystemPromptPage = () => {
  return (
    <div className="flex flex-col items-center p-8 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-8">Add a System Prompt</h1>
      <div className="w-full max-w-md">
        <p className="text-gray-600 mb-4">Example: "I would like you to take the context of this paper and apply it to my thesis [enter thesis]. Please explain how the research paper might be remixed with my thesis."</p>
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="system-prompt">
          System Prompt
        </label>
        <textarea
          id="system-prompt"
          className="w-full h-32 px-4 py-2 mb-4 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter your system prompt here"
        />
        <div className="flex justify-end">
          <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSystemPromptPage;
