// src/pages/SelectedPaperPage.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import PaperContent from '../components/PaperContent';
import PaperTasks from '../components/PaperTasks'; 
import { GET_RESEARCH_PAPER_BY_ID } from '../graphql/papers/papersQueries'; 

const SelectedPaperPage = () => {
  const { paperId } = useParams<{ paperId?: string }>();
  const { loading, error, data } = useQuery(GET_RESEARCH_PAPER_BY_ID, {
    variables: { id: paperId },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!paperId) return <p>Invalid paper ID.</p>;

  return (
    <div className="p-8">
      <PaperContent paper={data.researchPaper} />
      {paperId && <PaperTasks researchPaperId={paperId} />}
    </div>
  );
};

export default SelectedPaperPage;
