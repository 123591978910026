import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AgentsPage = () => {
  const navigate = useNavigate();
  const [selectedAgent, setSelectedAgent] = useState('');

  const handleCreateAgentClick = () => {
    navigate('/agents/create-an-agent');
  };

  const handleAgentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAgent(event.target.value);
  };

  const placeholderAgents = [
    {
      name: "Professor",
      description: "I can explain papers and make them easier to understand.",
    },
    {
      name: "Venture Studio Director",
      description: "I think of innovative ideas that can be created out of research papers.",
    },
    {
      name: "Your Agent 1",
      description: "This is a placeholder for your agent.",
    },
    {
      name: "Your Agent 2",
      description: "This is a placeholder for your agent.",
    }
  ];

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-center">Agents</h1>
        <div className="flex justify-center mb-12">
          <button
            onClick={handleCreateAgentClick}
            className="px-8 py-4 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition"
          >
            Create an Agent
          </button>
        </div>

        <h2 className="text-xl font-bold mb-6">Public Agents</h2>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-12">
          {placeholderAgents.slice(0, 2).map((agent, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-lg font-semibold mb-2">{agent.name}</h3>
              <p className="text-sm text-gray-600">{agent.description}</p>
            </div>
          ))}
        </div>

        <h2 className="text-xl font-bold mb-6">Your Agents</h2>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-12">
          {placeholderAgents.slice(2).map((agent, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-lg font-semibold mb-2">{agent.name}</h3>
              <p className="text-sm text-gray-600">{agent.description}</p>
            </div>
          ))}
        </div>

        <h2 className="text-xl font-bold mb-6">Chat with an Agent</h2>
        <div className="bg-white shadow-md rounded-lg p-6">
          <div className="flex flex-col items-center">
            <label htmlFor="agent-select" className="block text-gray-700 text-sm font-bold mb-2">
              Select an Agent
            </label>
            <select
              id="agent-select"
              value={selectedAgent}
              onChange={handleAgentChange}
              className="w-full max-w-md px-4 py-2 mb-4 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="" disabled>Select an agent...</option>
              {placeholderAgents.map((agent, index) => (
                <option key={index} value={agent.name}>{agent.name}</option>
              ))}
            </select>
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
              disabled={!selectedAgent}
            >
              Chat with Agent (Coming Soon)
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentsPage;
