import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return <button onClick={() => loginWithRedirect()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Receive Free arXiv Summaries</button>;
};

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
      Log Out
    </button>
  );
};

// <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>


const HomePage = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <div className="flex justify-center items-center h-screen flex-col space-y-4">
      <h1 className="text-3xl font-bold">rezearch AI</h1>
      <p className="text-xl">papers made practical</p>
      {!isAuthenticated ? (
        <>
          <LoginButton />
          <Link to="/papers"><button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">View Papers Feed</button></Link>
          <Link to="/workflows"><button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">View Workflows</button></Link>
        </>
      ) : (
        <>
          <LogoutButton />
          <Link to="/papers"><button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">View Papers Feed</button></Link>
          <Link to="/workflows"><button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">View Workflows</button></Link>
        </>
      )}
    </div>
  );
};

export default HomePage;