import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

// Initialize GA4 with your tracking ID
const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID as string;
ReactGA.initialize(TRACKING_ID);

// Custom hook to track page views
export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Sends a pageview hit to GA4 whenever the route changes
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
};

// Component to use the custom hook
export const PageTracker: React.FC = () => {
  usePageTracking();
  return null; // This component does not render anything
};
