import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_RESEARCH_PAPERS } from '../graphql/papers/papersQueries';
import { Link } from 'react-router-dom';

const PapersPage = () => {
  const { loading, error, data } = useQuery(GET_RESEARCH_PAPERS, {
    fetchPolicy: "network-only", // Ignore the cache, fetch from network
  });

  if (loading) return <p className="text-center text-lg">Loading...</p>;
  if (error) return <p className="text-red-500 text-center text-lg">Error: {error.message}</p>;

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold text-center mb-8">Research Feed</h2>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
        {data.researchPapers.map((paper: any) => (
          <div key={paper.id} className="bg-white shadow-md rounded-lg p-6">
            <Link to={`/papers/${paper.id}`} className="text-xl font-semibold mb-2 hover:underline">
              {paper.title}
            </Link>
            <p className="text-gray-700 text-base mb-4">{paper.summary}</p>
            <div className="text-gray-600 text-sm">
              <p>Authors: {paper.authors.join(', ')}</p>
              <p>Category: {paper.categoryName}</p>
            </div>
            <Link to={`/papers/${paper.id}`} className="mt-4 inline-block bg-blue-500 text-white rounded-full px-6 py-2 text-sm font-semibold">Read More</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PapersPage;
