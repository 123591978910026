// Assuming you're using useState and useEffect from React
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 768) {
        setIsMobileMenuOpen(false);
      }
    }
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav className="bg-gray-800 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex space-x-4">
          <button 
            className="md:hidden px-2 py-1 rounded-md text-white"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle navigation menu"
            aria-expanded={isMobileMenuOpen}
          >
            {/* Replace with an icon and change it based on the menu state */}
            <span>{isMobileMenuOpen ? 'Close' : 'Menu'}</span>
          </button>
          <div className={`flex-col md:flex-row md:flex space-x-0 md:space-x-4 ${isMobileMenuOpen ? 'flex' : 'hidden'}`}>
            <Link to="/" className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium block">Home</Link>
            <Link to="/papers" className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium block">Papers</Link>
            <Link to="/workflows" className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium block">Workflows</Link>
            <Link to="/agents" className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium block">Agents</Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
