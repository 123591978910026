// /src/graphql/papers/papersQueries.ts
import { gql } from '@apollo/client';

export const GET_RESEARCH_PAPERS = gql`
  query GetResearchPapers {
    researchPapers {
      id
      title
      summary
      authors
      categoryName
      link
      pdfLink
      createdAt
    }
  }
`;

export const GET_RESEARCH_PAPER_BY_ID = gql`
  query GetResearchPaperById($id: ID!) {
    researchPaper(id: $id) {
      id
      createdAt
      lastUpdatedAt
      title
      link
      pdfLink
      summary
      firstAuthor
      firstAuthorAffiliation
      lastAuthor
      authors
      categoryName
      categoryCode
      isUserSubmitted
      submittedByUserID
      associatedTaskIds
    }
  }
`;

export const GET_TASKS_BY_RESEARCH_PAPER_ID = gql`
  query GetTasksByResearchPaperId($researchPaperId: ID!) {
    tasksbyResearchPaperId(researchPaperId: $researchPaperId) {
      id
      type
      output
    }
  }
`;
