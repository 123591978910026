import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_TASKS_BY_TYPE } from '../graphql/workflows/eli12Queries';

// Your existing Task interface
interface Task {
  id: string;
  type: string;
  context: string;
  output: string;
  createdAt: string;
}

// Define an interface for the query response data that matches the structure of your GraphQL query
interface GetTasksByTypeData {
  tasksByType: Task[];
}

// Define an interface for the query variables if you have any
interface GetTasksByTypeVars {
  type: string;
}

const ELI12Page = () => {
  // Use the interfaces for specifying the type of data and variables in useQuery
  const { loading, error, data } = useQuery<GetTasksByTypeData, GetTasksByTypeVars>(GET_TASKS_BY_TYPE, {
    variables: { type: 'ELI-12 Summary' },
    fetchPolicy: "cache-first",
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div style={{ padding: '1rem', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>ELI-12 Summaries</h1>
      {/* Ensure data is not undefined before mapping */}
      {data?.tasksByType.map((task: Task) => (
        <article key={task.id} style={{ marginBottom: '1rem', border: '1px solid #ddd', padding: '0.5rem', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
          <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: task.output }} />
        </article>
      ))}
    </div>
  );
};

export default ELI12Page;
