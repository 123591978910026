import React from 'react';
import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { GET_TOPIC_BY_URL_ROUTE, GET_TASKS_BY_TOPIC_ID } from '../graphql/workflows/ycRFSQueries';

interface ResearchPaper {
  id: string;
  title: string;
  firstAuthor: string;
  categoryName: string;
  categoryCode: string;
}

interface Task {
  id: string;
  type: string;
  context: string;
  output: string;
  createdAt: string;
  researchPaper: ResearchPaper;
  relationships: {
    topicId: string;
  };
}

interface TasksByTopicIdResponse {
  tasksByTopicId: Task[];
}

interface TopicByUrlRouteResponse {
  topicByUrlRoute: {
    id: string;
    topic: string;
    description: string;
    urlRoute: string;
    createdAt: string;
    updatedAt: string;
    associatedTaskIds: string[];
  };
}

const YCRFSTopicPage = () => {
  const { urlRoute } = useParams<{ urlRoute: string }>();

  // Fetch the topic by urlRoute
  const { data: topicData, loading: topicLoading, error: topicError } = useQuery<TopicByUrlRouteResponse>(GET_TOPIC_BY_URL_ROUTE, {
    variables: { urlRoute },
    fetchPolicy: "cache-first",
  });
  console.log("URL Route: ", urlRoute)

  // Extract topicId from the fetched topic data for subsequent task fetching
  const topicId = topicData?.topicByUrlRoute?.id;
  console.log("Topic ID: ", topicId);

  // Fetch tasks associated with the topicId when topicId is available
  const { loading: tasksLoading, error: tasksError, data: tasksData } = useQuery<TasksByTopicIdResponse>(GET_TASKS_BY_TOPIC_ID, {
    variables: { topicId },
    skip: !topicId, // Only execute this query when topicId is available
    fetchPolicy: "cache-first",
  });

  if (topicLoading || tasksLoading) return <p>Loading...</p>;
  if (topicError || tasksError) return <p>Error: {topicError?.message || tasksError?.message}</p>;
  
  return (
    <div style={{ padding: '1rem', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>YC-RFS Topic Workflow</h1>
      <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#333', marginBottom: '0.25rem' }}>
        Topic: {topicData?.topicByUrlRoute?.topic}
      </h2>
      <div style={{ marginBottom: '1rem' }}>
        <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#333', marginBottom: '0.25rem' }}>Description:</h3>
        <p style={{ fontSize: '1rem', color: '#555', marginBottom: '1rem' }}>{topicData?.topicByUrlRoute?.description}</p>
      </div>
      {tasksData?.tasksByTopicId.map((task) => (
        <article key={task.id} style={{ marginBottom: '2rem', border: '1px solid #ddd', padding: '1rem', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
          {task.researchPaper && (
            <>
              <h4 style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>
                Research Paper:
                <Link
                  to={`/papers/${task.researchPaper.id}`}
                  className="ml-2 text-blue-600 hover:text-blue-800 underline hover:no-underline"
                >
                  {task.researchPaper.title}
                </Link>
              </h4>
              <p style={{ fontWeight: 'bold' }}>Author: {task.researchPaper.firstAuthor}</p>
              <p style={{ fontWeight: 'bold', marginBottom: '1rem' }}>Category: {task.researchPaper.categoryName} ({task.researchPaper.categoryCode})</p>
            </>
          )}
          <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: task.output }} />
        </article>
      ))}
    </div>
  );
};

export default YCRFSTopicPage;
