// src/pages/WorkflowsPage.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const WorkflowsPage = () => {
  const workflows = [
    {
      name: "ELI-12 Summaries",
      alias: "ELI-12",
      description: "This workflow will generate simplified summaries of the main concepts discussed in the research paper."
    },
    {
      name: "YC Request For Startups",
      alias: "YC-RFS",
      description: "This workflow will take the concepts from research papers and generate ideas related to YC Request for Startups topics."
    }
  ];

  return (
    <div className="p-8">
      <h2 className="text-xl font-bold mb-4">Workflows</h2>
      <div className="grid md:grid-cols-2 gap-4">
        {workflows.map((workflow) => (
          <div key={workflow.alias} className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-lg font-semibold">{workflow.name}</h3>
            <p className="text-sm text-gray-600 mb-4">{workflow.description}</p>
            <Link to={`/workflows/${workflow.alias}`}
                  className="inline-block bg-blue-500 text-white text-sm font-semibold px-4 py-2 rounded hover:bg-blue-600">
              View Details
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkflowsPage;
