// /src/graphql/workflows/ycRFSQueries.ts
import { gql } from '@apollo/client';

export const GET_TOPICS = gql`
  query GetTopics {
    ycTopics {
      id
      topic
      description
      urlRoute
    }
  }
`;

export const GET_TOPIC_BY_URL_ROUTE = gql`
  query TopicByUrlRoute($urlRoute: String!) {
    topicByUrlRoute(urlRoute: $urlRoute) {
      id
      topic
      description
      urlRoute
      createdAt
      updatedAt
      associatedTaskIds
    }
  }
`;

export const GET_TASKS_BY_TOPIC_ID = gql`
  query GetTasksByTopicId($topicId: ID!) {
    tasksByTopicId(topicId: $topicId) {
      id
      type
      context
      output
      createdAt
      researchPaper {
        id
        title
        firstAuthor
        categoryName
        categoryCode
        link
        pdfLink
        summary
        lastAuthor
        authors
        isUserSubmitted
        submittedByUserID
      }
      relationships {
        topicId
      }
    }
  }
`;
