// /src/graphql/workflows/workflowQueries.ts
import { gql } from '@apollo/client';

export const GET_TASKS_BY_TYPE = gql`
  query GetTasksByType($type: String!) {
    tasksByType(type: $type) {
      id
      type
      context
      output
      createdAt
      }
  }
`;
