// src/components/PaperContent.tsx
import React from 'react';

interface ResearchPaper {
  id: string;
  createdAt: string;
  lastUpdatedAt: string;
  title: string;
  link: string;
  pdfLink: string;
  summary: string;
  firstAuthor: string;
  firstAuthorAffiliation: string;
  lastAuthor: string;
  authors: string[];
  categoryName: string;
  categoryCode: string;
  isUserSubmitted?: boolean;
  submittedByUserID?: string | null;
  associatedTaskIds?: string[];
}

// Specify the type of props PaperContent expects
interface PaperContentProps {
  paper: ResearchPaper;
}

const PaperContent: React.FC<PaperContentProps> = ({ paper }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-3xl font-bold mb-2">{paper.title}</h2>
      <p className="text-gray-700 mb-4">{paper.summary}</p>
      <div className="text-gray-600 mb-4">
        {/* <p><strong>ID:</strong> {paper.id}</p> */}
        {/* <p><strong>Created At:</strong> {paper.createdAt}</p>
        <p><strong>Last Updated At:</strong> {paper.lastUpdatedAt}</p> */}
        <p><strong>First Author:</strong> {paper.firstAuthor} ({paper.firstAuthorAffiliation})</p>
        <p><strong>Last Author:</strong> {paper.lastAuthor}</p>
        <p><strong>Authors:</strong> {paper.authors.join(', ')}</p>
        <p><strong>Category:</strong> {paper.categoryName} ({paper.categoryCode})</p>
      </div>
      <a href={paper.link} target="_blank" rel="noopener noreferrer" className="inline-block bg-blue-500 text-white rounded-full px-6 py-2 text-sm font-semibold mr-2">Read Paper</a>
      <a href={paper.pdfLink} target="_blank" rel="noopener noreferrer" className="inline-block bg-blue-500 text-white rounded-full px-6 py-2 text-sm font-semibold">Download PDF</a>
    </div>
  );
};

export default PaperContent;
